<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description> 
            В Atelier Chardon Savard, школе дизайна и стиля, основанной в 1988 году Сириллом Шардоном и Домиником Саваром, атмосфера исследовательской лаборатории, полной жизни и обучения. Творчество - основная цель сотрудников и учеников школы. «Творчеству можно научить и научиться» - это основной принцип школы.
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #youtube>
            <iframe width="450" height="315" src="https://www.youtube.com/watch?v=pjb9TNGsSFM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>
        <template #speciality>
            Fashion & Design
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="fd">Fashion Designer</option>
                                <option value="dm">Designer de Mode</option>
                                <option value="cm">MASTÈRE CRÉATEUR DE MODE</option>
                                </select>
                        </div>
                    </div>
                    
                    <Program :url="''" v-if="this.program === 'fd'" id="fd">
                        <template #title>
                            Fashion Designer
                        </template>
                        <template #description>
                            <p>
                                Зачисление после получения аттестата средней школы. Продолжительность: 3 года, за которыми следует год профессионализации. Признанная государственная степень II уровня - степень бакалавра (аккредитована французской комиссией по аккредитации).
                            </p>
                            <p>
                                Во время программы Fashion Designer вы анализируете бренды, расшифровываете тенденции, иллюстрируете свои идеи. Вы создаете свои коллекции в мастерских, как это делают модные стилисты.
                            </p>
                            <p>
                                Каждый год все студенты курса Fashion Designer имеют возможность создавать командные коллекции и представлять их на показе мод, проходящем в символическом месте Парижа.                            
                            </p>
                        </template>
                        <template #duree>
                            3-4 года
                        </template>
                        <template #language>
                             английский
                        </template>
                        <template #cost>
                            11.690 евро/год
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                            английский уровня B2, среднее образование, досье.
                        </template>
                        <template #start-time>
                            сентябрь 
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'dm'" id="dm">
                        <template #title>
                            Designer de Mode
                        </template>
                        <template #description>
                            <p>
                                Поступление после бакалавриата или сразу на 2-й и 3-й курс в зависимости от вашего уровня и опыта. Продолжительность: 3 года, за которыми следует год профессионального опыта. Ваша будущая работа: стать дизайнером одежды (мужчины-женщины-аксессуары) для секторов высокой моды, luxe, prêt-à-porter.
                            </p>
                            <p>
                                Ваша миссия будет заключаться в том, чтобы придумывать, рисовать, создавать модели, которые составят коллекции завтрашнего дня. 
                            </p>
                        </template>
                        <template #duree>
                            3-4 года
                        </template>
                        <template #language>
                            французский или английский
                        </template>
                        <template #cost>
                            7.790-10.990 евро/год
                        </template>
                        <template #campus>
                            Париж, Нант
                        </template>
                        <template #requirements>
                            английский или французский уровня B2, среднее образование, досье.
                        </template>
                        <template #start-time>
                            сентябрь 
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'cm'" id="cm">
                        <template #title>
                            MASTÈRE CRÉATEUR DE MODE
                        </template>
                        <template #description>
                            <p>
                                За два года школа подготовит вас к множеству навыков независимого модельера или художественного руководителя (разработка и управление). Вы будете определять инновационную, креативную и актуальную концепцию в отношении выбранного вами сектора.
                            </p>
                            <p>
                                Вы будете создавать модели из своей личной коллекции, будете организовывать и контролировать свое дефиле в целом (кастинг, прическа, макияж, укладка, музыка ...). Вы разработаете свое профессиональное портфолио и представите бизнес-модель своего бренда.
                            </p>
                        </template>
                        <template #duree>
                            2 года
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            11.490 - первый год, 2500 евро - второй год.
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                            французский уровня B2, высшее образование (минимум 3 года), досье.
                        </template>
                        <template #start-time>
                            сентябрь 
                        </template>
                    </Program>

                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
        <template #campuses>
            <Campus>
                <template #localisation>
                    Париж
                </template>
                <template #description>
                    школа расположена в 11-м округе Парижа. 
                    В бывшей типографии, в самом сердце района Оберкампф, в центре Парижа, школа моды предоставляет вам доступ к комнатам для шитья, вязания, компьютерной графики, рисования и стиля на площади более 1500 м2. С начала 2018 учебного года школа также открыла помещение на улице Бельвю (19-й округ Парижа).
                </template>
                <template #iframe-youtube>
                    <iframe width="450" height="315" src="https://www.youtube.com/watch?v=EfEVZOYPBnk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
            </Campus>
            <Campus>
                <template #localisation>
                    Нант
                </template>
                <template #description>
                    студенты обучаются в самом сердце городского сообщества Nantes Métropole. Кампус школы в Нанте открыт с 2010 года.
                </template>
                <template #iframe-youtube>
                    <iframe width="450" height="315" src="https://www.youtube.com/watch?v=w944y119OVQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
            </Campus>
        </template>
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>
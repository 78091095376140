<template>
    <div class="schools">
        <Navbar/>
        <Main/>
        <Menu/>
    </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import Main from '@/components/schools/fashion-schools/atelier-chardon/Main.vue'
import Menu from '@/components/schools/fashion-schools/atelier-chardon/Menu.vue'
import {stickyAndScrollHelper} from '@/helpers/Functions.js'
export default {
  name: 'AtelierChardon',
  components: {
    Navbar,
    Main,
    Menu
  },
  mounted(){
    stickyAndScrollHelper()
  }
}
</script>